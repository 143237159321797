/*
Template Name: Material Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
.menuToggleIcon{
  color : $white !important;
}
.navColor{
  background-color: $headerBG;
}
.headerIcon{
  font-size: 30px !important;
  margin-right: 10px !important;
  color: white !important;
}
.mat-menu-content:not(:empty) {
  padding-top: 0px !important;
 padding-bottom: 0px !important;
}
/*******************
 Topbar
*******************/
mat-toolbar {
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.5);
  &.topbar {
    padding: 0px 15px;
  }
  .navbar-header {
    text-align: center;
    .navbar-brand {
      display: flex;
      line-height: 18px;
      align-items: center;
      .dark-logo {
        display: none;
      }
      b {
        margin-right: 5px;
      }
    }
  }

  .profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }
}
/*******************
 Topbar
*******************/
.topbar {
  position: relative;
  /* Make sure the toolbar will stay on top of the content as it scrolls past. */
  z-index: 2;
}

.example-sidenav-container {
  /* When the sidenav is not fixed, stretch the sidenav container to fill the available space. This
     causes `<mat-sidenav-content>` to act as our scrolling element for desktop layouts. */
  flex: 1;
}

.example-sidenav-container {
  /* When the sidenav is fixed, don't constrain the height of the sidenav container. This allows the
     `<body>` to be our scrolling element for mobile layouts. */
  flex: 1 0 auto;
}
