/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* You can add global styles to this file, and also import other style files */
.mat-toolbar-row,
.mat-toolbar-single-row {
  height: 50px !important;
}

/* .mat-toolbar.mat-primary {
    background: rgba(38, 198, 218, .7) !important;
    color: white;
} */
/* mat-icon.mat-icon.notranslate.material-icons.mat-icon-no-color {
    color: #9c1006;
} */
.mymegamenu {
  /* height: 250px; */
  width: 400px;
  /* padding-top:2px; */
  margin-top: 8px
}

.searchField {
  width: 150px !important;
}

.menu-item-hover {
  transition: opacity 0.9s, margin-left 0.5s, margin-right 0.5s;
  -webkit-transition: opacity 0.9s, margin-left 0.5s, margin-right 0.5s;
}

.menu-item-hover:hover {
  margin-left: 10px;
  font-weight: 600;
}

.mat-elevation-z8-landing {
  /* box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%) !important; */
  box-shadow: -1px 0px 6px 0px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 0px 14px 17px rgb(0 0 0 / 12%) !important;
  border-radius: 40px !important;
  background-color: white;
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%) !important;
}

.box-shadow {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%) !important;
}

.containerClass {
  margin: 30px;
  border-radius: 5px;
}

@media only screen and (max-width: 500px),
only screen and (max-width: 768px),
only screen and (max-width: 1024px) {
  .small-device {
    display: none;
  }

  .small-device-text {
    text-align: center !important;
  }

  .small-device-text-right-footer {
    text-align: center !important;
  }

  .right-side-div {
    border-radius: 40px !important;
  }
}

@media only screen and (max-width: 768px) {
  .right-side-footer {
    text-align: center !important;
  }

  .small-device-text {
    width: 35%;
  }

  .small-device-text-right-footer {
    width: 35%;
  }

  .right-side-div {
    border-radius: 40px !important;
    height: 88% !important;
  }
}

.sidebar-bg {
  background-color: #283593 !important;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(13px);
  /* border-radius: 10px; */
  /* border: 1px solid rgba(255, 255, 255, 0.18); */
}

@media only screen and (max-width: 1024px) {
  .right-side-div {
    /* background-color: red; */
    /* height: 100vh !important; */
    position: relative !important;
  }

  .right-side-form {
    top: 5% !important;
  }

  .right-side-footer {
    text-align: center !important;
  }

  .small-device-text {
    width: 100% !important;
  }

  .small-device-text-right-footer {
    width: 100% !important;
  }

  .fitl-logo {
    width: 50px !important;
  }

  .right-side-div {
    height: 88% !important;
  }
}

@media only screen and (max-width: 1440px) {
  .right-side-form {
    top: 10% !important;
    left: 0 !important;
    right: 0 !important;
    position: relative !important;
  }

  .right-side-form-reset-password {
    top: 0% !important;
    left: 0 !important;
    right: 0 !important;
    position: relative !important;
  }

  .small-device-text {
    width: 100%;
    /* font-size: 15px; */
  }

  .small-device-text-right-footer {
    width: 33%;
  }

  .right-top-icon {
    max-width: 33px;
    position: relative;
    left: 10%;
    top: 5%;
  }

  .right-top-icon-a {
    overflow: visible;
    flex: 1 1 10%;
    box-sizing: border-box;
    max-width: 10%;
    max-height: 10%;
  }

  .right-side-div {
    height: 88%;
  }

  .fitl-logo {
    width: 100px !important;
  }
}

@media only screen and (max-width: 2560px) {
  .right-side-form {
    top: 20%;
    left: 0 !important;
    right: 0 !important;
    position: relative !important;
  }

  .right-side-footer {
    text-align: center;
  }

  .small-device-text {
    width: 40%;
  }

  .small-device-text-right-footer {
    width: 33%;
  }

  .right-side-div {
    height: 88vh;
  }

  .fitl-logo {
    width: 200px;
  }
}

.hrz-center {
  /* margin: auto; */
  width: 50%;
  padding: 10px;
}

.right-side-footer a u {
  font-size: 10px;
}

.right-side-footer a {
  color: #2b2ef1;
}

right-side-footer-text {
  font-size: 15px;
}

.small-device {
  /* background-color: red; */
  margin-top: 30px;
}

.ct-label {
  fill: black !important;
  color: black !important;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1;
}

.landing-page-fixed-footer {
  margin-top: auto;
  position: relative;
  bottom: 0 !important;
  width: 100%;
}

.my-success-snackbar {
  background-color: #e3fce7 !important;
  color: #4c7053 !important;
  font-weight: 500;
}

.my-error-snackbar {
  background-color: #ffe0de !important;
  color: #b65047 !important;
  font-weight: 500;
}

.my-warning-snackbar {
  background-color: #f7b65c !important;
  color: white !important;
  font-weight: 500;
}

.my-info-snackbar {
  background-color: #79c0fa !important;
  color: white !important;
  font-weight: 500;
}

.alert-success {
  color: #28c76f !important;
  background-color: #d4f4e2 !important;
  padding: 0.1rem !important;
}

.ng-star-inserted img {
  margin: 0 5px;
}

.mat-button-base {
  border-radius: 30px !important;
}

.did-purple {
  background-color: #6a0dad !important;
  color: white !important;
}

.body-bg {
  background-color: #dbdce1 !important;
  /* display: flex; */
}

.mat-line {
  border-left: rgb(0 0 0 / 3%) solid !important;
}

.th-sl-common {
  width: 75px !important;
}

.th-pos-right {
  text-align: right !important;
}

th {
  font-size: 15px !important;
}

td {
  font-size: 15px !important;
}

.table-action-button {
  height: 25px !important;
  width: 25px !important;
}

mat-icon.mat-icon.notranslate.tbl-icon.material-icons.mat-icon-no-color {
  font-size: 15px;
  padding: 0px !important;
  display: inline-block;
  line-height: 7px !important;
}

.tab-footer {
  padding: 15px;
  display: flex;
  justify-content: end;
}

.mat-tab-label {
  padding: 0px !important;
  opacity: 0.8 !important;
  font-size: 13px !important;
  min-width: 119.1px !important;
}

.mat-tab-body-content {
  /* background-color: #f6f4f5 !important; */
}

.mat-tab-label-container {
  background-color: #283593 !important;
  border-radius: 5px 5px 0px 0px !important;
}

.mat-tab-label {
  color: white !important;
  border-right: 0.5px solid darkgray !important;
}

.mat-tab-label:hover {
  background-color: white !important;
  color: #283593 !important;
}

.mat-tab-label-active:hover {
  background-color: #283593 !important;
  color: whitesmoke !important;
}

.mat-tab-label-active {
  background-color: white;
  color: #283593 !important;
}

.padding-5px {
  padding: 5px !important;
}

fieldset {
  border: 1px solid #979797;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.update-button {
  background-color: #3276b1 !important;
  border-radius: 10px !important;
  color: white !important;
  font-size: 12px !important;
  padding: 0px 10px !important;
  cursor: pointer;
}

.save-button {
  display: flex;
  align-items: center;
  background-color: #5b835b !important;
  border-radius: 5px !important;
  color: white !important;
  font-size: 12px !important;
  padding: 0px 10px !important;
  border: 0px !important;
  cursor: pointer;
  width: 80px;
}

.disable-button {
  display: flex;
  align-items: center;
  background-color: #D8D8D8 !important;
  border-radius: 6px !important;
  color: white !important;
  font-size: 12px !important;
  padding: 0px 10px !important;
  border: 0px !important;
  width: 80px;
}

.cancel-button {
  display: flex;
  align-items: center;
  background-color: #E60B2F !important;
  border-radius: 6px !important;
  color: white !important;
  font-size: 12px !important;
  padding: 0px 5px 0px 0px !important;
  border: 0px !important;
  cursor: pointer;
  width: 80px;
}

.print-button {
  display: flex;
  align-items: center;
  background-color: brown !important;
  border-radius: 5px !important;
  color: white !important;
  font-size: 12px !important;
  padding: 0px 5px 0px 0px !important;
  border: 0px !important;
  cursor: pointer;
  width: 80px;
}

.search-button {
  display: flex;
  align-items: center;
  background-color: #2A9AEA !important;
  border-radius: 6px !important;
  color: white !important;
  font-size: 12px !important;
  padding: 0px 5px 0px 0px !important;
  border: 0px !important;
  cursor: pointer;
  width: 80px;
}

.print {
  display: flex;
  align-items: center;
  background-color: #A80153 !important;
  border-radius: 6px !important;
  color: white !important;
  font-size: 12px !important;
  padding: 0px 5px 0px 0px !important;
  border: 0px !important;
  cursor: pointer;
  width: 50px;
}
.deliver {
  display: flex;
  align-items: center;
  background-color:#3477B1!important;
  border-radius: 6px !important;
  color: white !important;
  font-size: 12px !important;
  padding: 0px 5px 0px 0px !important;
  border: 0px !important;
  cursor: pointer;
  width: 50px;
}

.view {
  display: flex;
  align-items: center;
  background-color: #4DA886 !important;
  border-radius: 6px !important;
  color: white !important;
  font-size: 12px !important;
  border: 0px !important;
  cursor: pointer;
  width: 50px;
}

.font-15 {
  font-size: 15px !important;
}

.font-12 {
  font-size: 12px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.text-center {
  text-align: center;
}

@media print {

  @page {
    margin: 5;
  }

  mat-toolbar {
    display: none !important;
  }

  mat-sidenav {
    display: none !important;
  }

  .page-break {
    page-break-after: always;
  }
}

/* mat tree */
.mat-tree {
  /* font-family: "Montserrat",
    Helvetica,
    Arial,
    serif !important; */
  font-family: "Roboto", sans-serif !important;
  ;
  font-size: 1.1rem !important;
}

.mat-list-item {
  /* font-family: "Montserrat",
    Helvetica,
    Arial,
    serif !important; */
  font-family: "Roboto", sans-serif !important;
  font-size: 1.1rem !important;
}

.dialogue-header {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  margin: -24px -24px 15px -24px !important;
  background-color: #283593 !important;
  padding: 5px 20px !important;
  border-radius: 3px;
  color: white;
}


.special-table tr:nth-child(even) {
  border: none !important;
}

.special-table tr:nth-child(odd) {
  border: none !important;
}

.errorText {
  color: #e91e63;
  font-weight: 600;
  font-size: 10px;
}

.ink-bar {
  background-color: #283593 !important;
}

/* changing the font family of mat table for whole project */

/* .mat-progress-spinner circle, .mat-spinner circle {
  stroke: grey !important;
} */



/* for accordion header  */
.mat-expanded .mat-expansion-panel-header {
  background-color: #cccccc !important;
  margin-bottom: 8px !important;
}


/* for paginatipn upper space  */
.mat-paginator {
  display: block !important;
  margin-top: 5px !important;
}
